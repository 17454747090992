.custom-text-bg {
  height: 60px;
}

.custom-text {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ffffffb3;
}
