.custom-popap-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 15px 15px;
  border-bottom: 1px solid rgba(var(--b-sc-rgb), 0.1);
}

.custom-popap-search {
    position: relative;
}