.custom-search {
  left: 0;
  right: auto;
}

.custom-btn-positione {
  left: 0;
  right: auto;
}

.custom-font-size {
  font-weight: 600;
}
