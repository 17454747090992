.custom-burger-nav {
  right: 0;
}

.custom-header-container {
  max-height: 36px;
  overflow: hidden;
}

.nav-menu-sub-custom {
  max-height: 200px;
  overflow: auto;
}
