.sgm-market-search-sports-book {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 0 0 0 2px var(--b);
  background: var(--b);
  z-index: 1;
}

.items-container {
  position: relative;
  height: 1415px;
}

.window-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.virtuoso-item-list {
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 336px;
  margin-top: 0px;
}
.item-overflow {
  overflow-anchor: none;
}

.sports-book {
  grid-template-columns: 1fr 330px;
  grid-template-areas:
    "header header"
    "content betslip"
    "footer footer";
}

.bottom-text {
  margin-top: 200px;
}

.custom-container {
  max-width: 100% !important;
  height: 100% !important;
  width: 90% !important;
  margin-top: 40px;
  min-width: 100% !important;
  margin-bottom: 100px;
}
