.casinoJackpotWrapper-bc {
  background: url(../../accets/images/png/jackpotBackground/jackpot-banner-bg.103b1588.png) no-repeat 0 0 / cover, radial-gradient( circle at 49% 48%, rgba(var(--hero-rgb), 0.7), rgba(var(--b-sc-rgb), 0.05) 100% );
}

.jackpot-pool-price {
  font-size: 12px;
  color: #fff;
  margin-top: 6px;
  font-weight: normal;
}


.jackpot-pool-price:after {
  content: attr(data-cur);
  -webkit-margin-start: 4px;
  margin-inline-start:4px}

.jackpot-pool-arrow-icon {
  color: hsla(0,0%,100%,.8);
  font-size: 11px
}

.jackpot-pool-list {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(var(--b-sc-rgb),.1)
}

.jackpot-pool-list:empty {
  display: none
}

.jackpot-pool-list-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background: rgba(0,0,0,.25);
  border-radius: 4px
}

.jackpot-pool-list-item:first-child {
  margin-top: 8px
}

.jackpot-pool-list-item+.jackpot-pool-list-item {
  margin-top: 5px
}

.jackpot-pool-list-item-header {
  display: flex;
  align-items: center
}

.jackpot-pool-status {
  color: gold;
  font-size: 14px;
  font-weight: 500;
  flex: auto
}

.jackpot-pool-info-date {
  font-size: 12px;
  color: hsla(0,0%,100%,.6);
  white-space: nowrap
}

.jackpot-pool-info {
  margin: 6px 0 0;
  font-size: 12px;
  color: hsla(0,0%,100%,.6);
}

.odometer.odometer-theme-default {
  display: flex !important;
  line-height: initial !important;
  font-family: inherit !important;
  //.odometer-formatting-mark {
  //  font-family: Roboto,Arial,Helvetica,sans-serif !important;
  //}
}
////
//.jackpot-widget-full {
//  font-family: Roboto,Arial,Helvetica,sans-serif;
//}

.jackpot-pool-wrapper {
  line-height: initial;
}