.search-custom {
  left: 0;
  right: auto;
}

.custom-btn-position {
  left: 0;
  right: auto;
}

.custom-font-size {
  font-weight: 600 !important;
}
