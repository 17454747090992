@font-face {
    font-family: "BetConstruct-Icons";
    src: url(../fonts/chunks/BetConstruct-Icons.e77508a6.ttf);
    src: url(../fonts/chunks/BetConstruct-Icons.e77508a6.ttf) format("embedded-opentype"),url(../fonts/chunks/BetConstruct-Icons.e77508a6.ttf) format("truetype"),url(../fonts/chunks/BetConstruct-Icons.e77508a6.ttf) format("woff"),url(../fonts/chunks/BetConstruct-Icons.e77508a6.ttf) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
  }  

[class*=" bc-i-"],[class^=bc-i-] {
    font-family: "BetConstruct-Icons"!important;
    font-style: normal;
    font-weight: 400;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.bc-i-default-icon:before {
    content: "\ea1a"
}

.bc-i-Go:before {
    content: "\e989"
}

.bc-i-Sepaktakraw:before {
    content: "\ea7a"
}

.bc-i-AgeofEmpires:before {
    content: "\ea58"
}

.bc-i-FreeFire:before {
    content: "\ea53"
}

.bc-i-4000235:before,.bc-i-ArenaofValor:before {
    content: "\ea38"
}

.bc-i-Headis:before {
    content: "\ea35"
}

.bc-i-Teqball:before {
    content: "\ea3d"
}

.bc-i-MobileLegends:before {
    content: "\e9f8"
}

.bc-i-ApexLegends:before {
    content: "\ea59"
}

.bc-i-Valorant:before {
    content: "\e9e4"
}

.bc-i-VirtualMarbleRacing:before {
    content: "\e9e5"
}

.bc-i-Kabaddi:before {
    content: "\e98a"
}

.bc-i-RainbowSix:before {
    content: "\e98b"
}

.bc-i-Petanque:before {
    content: "\e98c"
}

.bc-i-ClashRoyale:before {
    content: "\e98d"
}

.bc-i-Bodybuilding-icon:before {
    content: "\e98f"
}

.bc-i-FIFA:before {
    content: "\e990"
}

.bc-i-circuit:before {
    content: "\e991"
}

.bc-i-PS4GAMING:before {
    content: "\e992"
}

.bc-i-E-IceHockey:before {
    content: "\e993"
}

.bc-i-BetOnBlackJack:before {
    content: "\e995"
}

.bc-i-150025:before,.bc-i-LiveBetOnPoker:before,.bc-i-livebetonpoker:before {
    content: "\e996"
}

.bc-i-eSports_logo_sportbook:before {
    content: "\e997"
}

.bc-i-KingOfGlory:before {
    content: "\e998"
}

.bc-i-PUBG:before {
    content: "\e999"
}

.bc-i-RocketLeague:before {
    content: "\e99a"
}

.bc-i-WarcraftIII:before {
    content: "\e99e"
}

.bc-i-StreetFighterSymbol:before {
    content: "\e9a0"
}

.bc-i-Esports:before {
    content: "\e9a1"
}

.bc-i-CrossFire:before {
    content: "\e9a3"
}

.bc-i-Halo:before {
    content: "\e9a5"
}

.bc-i-blank:before {
    content: "\e670"
}

.bc-i-HON:before {
    content: "\e9a6"
}

.bc-i-Vainglory:before {
    content: "\e9a7"
}

.bc-i-VirtualCarRacing:before {
    content: "\e9a8"
}

.bc-i-CallOfDuty:before {
    content: "\e9a9"
}

.bc-i-CounterStrike:before {
    content: "\21"
}

.bc-i-LeagueofLegendsWildRift:before {
    content: "\ea5a"
}

.bc-i-LeagueOfLegends:before {
    content: "\22"
}

.bc-i-halo:before {
    content: "\23"
}

.bc-i-Dota2:before {
    content: "\24"
}

.bc-i-BrawlStars:before {
    content: "\ea5b"
}

.bc-i-HeroesOfTheStorm:before {
    content: "\25"
}

.bc-i-Hearthstone:before {
    content: "\26"
}

.bc-i-MortalKombatXL:before {
    content: "\27"
}

.bc-i-WorldOfTanks:before {
    content: "\28"
}

.bc-i-WorldOfWarcraft:before {
    content: "\29"
}

.bc-i-Overwatch:before {
    content: "\2a"
}

.bc-i-Smite:before {
    content: "\2b"
}

.bc-i-smash:before {
    content: "\2c"
}

.bc-i-StarCraft2:before,.bc-i-StarCraft:before {
    content: "\2d"
}

.bc-i-outright:before {
    content: "\e9aa"
}

.bc-i-financials:before {
    content: "\e9ab"
}

.bc-i-ETennis:before {
    content: "\e98e"
}

.bc-i-EBasketball:before {
    content: "\e9ac"
}

.bc-i-CyberFootball:before {
    content: "\e9ad"
}

.bc-i-Bowls:before {
    content: "\e800"
}

.bc-i-Futsal:before {
    content: "\2e"
}

.bc-i-vbet-sport-icons_tablehockey:before {
    content: "\2f"
}

.bc-i-vbet-sport-icons_tankbiathlon:before {
    content: "\32"
}

.bc-i-TVShowsAndMovies:before {
    content: "\33"
}

.bc-i-MarathonSwimming:before {
    content: "\ea43"
}

.bc-i-Olympics:before {
    content: "\ea44"
}

.bc-i-Skateboarding:before {
    content: "\ea45"
}

.bc-i-SynchronizedSwimming:before {
    content: "\ea46"
}

.bc-i-Climbing:before {
    content: "\ea47"
}

.bc-i-Equestrian:before {
    content: "\ea48"
}

.bc-i-Karate:before {
    content: "\ea49"
}

.bc-i-150027:before,.bc-i-bingo:before {
    content: "\ea4a"
}

.bc-i-instantgame:before {
    content: "\ea3e"
}

.bc-i-vbet-sport-icons_yachting:before {
    content: "\3e"
}

.bc-i-Balls:before {
    content: "\e607"
}

.bc-i-sport:before {
    content: "\e600"
}

.bc-i-AmericanFootball:before {
    content: "\e602"
}

.bc-i-Angling:before {
    content: "\e603"
}

.bc-i-PistolHead2Head:before,.bc-i-PistolShooting:before {
    content: "\ea37"
}

.bc-i-ArcheryH2H:before,.bc-i-CompoundArchery:before {
    content: "\e604"
}

.bc-i-CompoundArchery1:before {
    content: "\e605"
}

.bc-i-Archery:before {
    content: "\ea41"
}

.bc-i-AustralianFootball:before {
    content: "\e606"
}

.bc-i-Badminton:before {
    content: "\e609"
}

.bc-i-3x3:before,.bc-i-3x3Basketball:before {
    content: "\ea4b"
}

.bc-i-Softball:before {
    content: "\e653"
}

.bc-i-Baseball:before {
    content: "\e608"
}

.bc-i-Tennis:before,.bc-i-vbet-sport-icons_tennis:before {
    content: "\e663"
}

.bc-i-vbet-sport-icons_volleyball:before,.bc-i-Volleyball:before {
    content: "\e66a"
}

.bc-i-Basketball:before {
    content: "\e671"
}

.bc-i-Football:before,.bc-i-Soccer:before {
    content: "\e66f"
}

.bc-i-BasketballShots:before {
    content: "\e9e2"
}

.bc-i-BeachHandball:before {
    content: "\e60a"
}

.bc-i-BeachFootball:before {
    content: "\e60b"
}

.bc-i-BeachVolleyball:before {
    content: "\e60c"
}

.bc-i-Biathlon:before {
    content: "\e60d"
}

.bc-i-Bobsleigh:before {
    content: "\e60e"
}

.bc-i-Boxing:before {
    content: "\e60f"
}

.bc-i-Bridge:before {
    content: "\e610"
}

.bc-i-Chess:before {
    content: "\e612"
}

.bc-i-Curling:before {
    content: "\e614"
}

.bc-i-Cycling:before {
    content: "\e615"
}

.bc-i-4000240:before,.bc-i-Cyclo-Cross:before,.bc-i-VirtualBicycle:before {
    content: "\e616"
}

.bc-i-Darts:before {
    content: "\e617"
}

.bc-i-Diving:before {
    content: "\e618"
}

.bc-i-Eurovision:before {
    content: "\e61a"
}

.bc-i-X-Sports:before {
    content: "\e61b"
}

.bc-i-Fencing:before {
    content: "\e61c"
}

.bc-i-FigureSkating:before {
    content: "\e61d"
}

.bc-i-Floorball:before {
    content: "\e61e"
}

.bc-i-GaelicFootball:before {
    content: "\e621"
}

.bc-i-3320009:before,.bc-i-Golf:before {
    content: "\e622"
}

.bc-i-GrassHockey:before {
    content: "\e623"
}

.bc-i-Gymnastics:before {
    content: "\e625"
}

.bc-i-Handball:before {
    content: "\e626"
}

.bc-i-Weightlifting:before {
    content: "\e627"
}

.bc-i-AirHockey:before {
    content: "\e9eb"
}

.bc-i-Cricket:before {
    content: "\e613"
}

.bc-i-BallHockey:before {
    content: "\e628"
}

.bc-i-Hockey:before {
    content: "\e629"
}

.bc-i-Hurling:before {
    content: "\e62b"
}

.bc-i-IceHockey:before {
    content: "\e62c"
}

.bc-i-Judo:before {
    content: "\e62d"
}

.bc-i-Lacross:before {
    content: "\e62e"
}

.bc-i-Livemonitor:before {
    content: "\e630"
}

.bc-i-Lottery:before {
    content: "\e631"
}

.bc-i-Mafia:before {
    content: "\e633"
}

.bc-i-MiniSoccer:before {
    content: "\e634"
}

.bc-i-420070:before,.bc-i-4000238:before,.bc-i-Greyhounds:before,.bc-i-VirtualGreyhoundRacing:before {
    content: "\e624"
}

.bc-i-PMUHorseRacing:before {
    content: "\e9ec"
}

.bc-i-FutureHorseRacing:before {
    content: "\e62a"
}

.bc-i-ModernPentathlon:before {
    content: "\e635"
}

.bc-i-420071:before,.bc-i-4000509:before,.bc-i-HorseRacing:before,.bc-i-HorseRacingSpecials:before,.bc-i-VirtualHorses:before,.bc-i-VSHorses:before {
    content: "\e668"
}

.bc-i-VirtualDogs:before,.bc-i-VirtualHorseRacing:before {
    content: "\e667"
}

.bc-i-Motorsport:before {
    content: "\e637"
}

.bc-i-4000239:before,.bc-i-Motorbikes:before {
    content: "\e636"
}

.bc-i-AutoRacing:before,.bc-i-Rally:before {
    content: "\e643"
}

.bc-i-Nascar:before {
    content: "\e638"
}

.bc-i-VirtualDragRacing:before {
    content: "\e9ed"
}

.bc-i-Formula1:before {
    content: "\e61f"
}

.bc-i-Netball:before {
    content: "\e639"
}

.bc-i-WinterOlympics:before {
    content: "\e63b"
}

.bc-i-Oscar:before {
    content: "\e63c"
}

.bc-i-Pesapallo:before {
    content: "\e63e"
}

.bc-i-PokerLive:before {
    content: "\e63f"
}

.bc-i-Poker:before {
    content: "\e640"
}

.bc-i-Politics:before {
    content: "\e641"
}

.bc-i-Pool:before {
    content: "\e642"
}

.bc-i-RinkHockey:before {
    content: "\e644"
}

.bc-i-Canoeing:before {
    content: "\e611"
}

.bc-i-Rowing:before {
    content: "\e645"
}

.bc-i-RugbySevens:before {
    content: "\e646"
}

.bc-i-RugbyLeague:before {
    content: "\e647"
}

.bc-i-RugbyUnion:before {
    content: "\e648"
}

.bc-i-Shooting:before {
    content: "\e64a"
}

.bc-i-Skeleton:before {
    content: "\e64c"
}

.bc-i-Swimming:before {
    content: "\e65c"
}

.bc-i-vbet-sport-icons_waterpolo:before,.bc-i-WaterPolo:before {
    content: "\e66b"
}

.bc-i-Schwingen:before {
    content: "\e649"
}

.bc-i-FreestyleWrestling:before {
    content: "\e620"
}

.bc-i-GrecoRomanWrestling:before,.bc-i-Wrestling:before,.bc-i-WWE:before {
    content: "\e672"
}

.bc-i-Athletics:before {
    content: "\e62f"
}

.bc-i-Luge:before {
    content: "\e632"
}

.bc-i-Speedskating:before {
    content: "\e655"
}

.bc-i-ShortTrackSpeedSkating:before {
    content: "\e64b"
}

.bc-i-Skis:before {
    content: "\e64e"
}

.bc-i-AlpineSkiing:before,.bc-i-CrossCountrySkiing:before {
    content: "\e601"
}

.bc-i-SkiJumping:before {
    content: "\e64d"
}

.bc-i-NordicCombined:before {
    content: "\e63a"
}

.bc-i-Snooker:before {
    content: "\e64f"
}

.bc-i-Snowboard:before {
    content: "\e650"
}

.bc-i-400037037:before,.bc-i-VirtualFootballPro:before {
    content: "\e651"
}

.bc-i-SoccerWC2014:before {
    content: "\e652"
}

.bc-i-SpecialBets:before {
    content: "\e654"
}

.bc-i-Speedway:before {
    content: "\e656"
}

.bc-i-SportAndFun:before {
    content: "\e657"
}

.bc-i-Sumo:before {
    content: "\e65a"
}

.bc-i-Surfing:before {
    content: "\e65b"
}

.bc-i-SynchronisedSwimming:before {
    content: "\e65d"
}

.bc-i-Tablehockey:before {
    content: "\e65e"
}

.bc-i-Taekwondo:before,.bc-i-vbet-sport-icons_taekwondo:before {
    content: "\e660"
}

.bc-i-Tankbiathlon:before {
    content: "\e661"
}

.bc-i-Telecasts:before {
    content: "\e662"
}

.bc-i-Triathlon:before,.bc-i-vbet-sport-icons_triathlon:before {
    content: "\e664"
}

.bc-i-Mma:before,.bc-i-SportFight:before,.bc-i-UFS:before,.bc-i-UltimateFighting:before,.bc-i-vbet-sport-icons_ultimatefighting:before {
    content: "\e666"
}

.bc-i-Padel:before {
    content: "\e63d"
}

.bc-i-Squash:before {
    content: "\e659"
}

.bc-i-4000236:before,.bc-i-VirtualTennis:before {
    content: "\e669"
}

.bc-i-TableTennis:before,.bc-i-vbet-sport-icons_tabletennis:before {
    content: "\e65f"
}

.bc-i-missed-penalty:before {
    content: "\ea08"
}

.bc-i-ThePenaltyKicks:before {
    content: "\e994"
}

.bc-i-55:before,.bc-i-games:before {
    content: "\e962"
}

.bc-i-games-1:before {
    content: "\eab9"
}

.bc-i-games-2:before {
    content: "\ead2"
}

.bc-i-333:before,.bc-i-penality:before,.bc-i-penalty:before {
    content: "\e9d5"
}

.bc-i-WhatWhereWhen:before {
    content: "\e66c"
}

.bc-i-bet-builder-history:before,.bc-i-bet-builder:before {
    content: "\eaaf"
}

.bc-i-view-skins:before {
    content: "\eab1"
}

.bc-i-layers:before {
    content: "\ea09"
}

.bc-i-booking:before {
    content: "\eaae"
}

.bc-i-toharmony:before {
    content: "\eaaa"
}

.bc-i-push-notification-fill:before {
    content: "\eaa8"
}

.bc-i-push-notification-outline:before {
    content: "\eaa9"
}

.bc-i-zoom-in:before {
    content: "\eaa5"
}

.bc-i-zoom-out:before {
    content: "\eaa6"
}

.bc-i-slotsforbonuses:before {
    content: "\eab2"
}

.bc-i-promotions:before {
    content: "\eaa3"
}

.bc-i-promotions-2:before {
    content: "\ead3"
}

.bc-i-promotions-3:before {
    content: "\eae1"
}

.bc-i-gamesoftheweek:before {
    content: "\eaa4"
}

.bc-i-gamesoftheweekwithvpn:before {
    content: "\ea9c"
}

.bc-i-jackpotswithvpn:before {
    content: "\ea9d"
}

.bc-i-newwithvpn:before {
    content: "\ea9e"
}

.bc-i-popularwithvpn:before {
    content: "\ea9f"
}

.bc-i-topwithvpn:before {
    content: "\eaa0"
}

.bc-i-promotionswithvpn:before {
    content: "\eaa1"
}

.bc-i-chinesegames:before {
    content: "\ea8c"
}

.bc-i-livedealer:before {
    content: "\eab3"
}

.bc-i-sportslots:before {
    content: "\ea8d"
}

.bc-i-summerslots:before {
    content: "\ea8e"
}

.bc-i-fruitslots:before {
    content: "\ea8f"
}

.bc-i-saintpatrick:before {
    content: "\ea90"
}

.bc-i-deluxe:before {
    content: "\ea91"
}

.bc-i-aliensandrobots:before {
    content: "\ea92"
}

.bc-i-vegas:before {
    content: "\ea93"
}

.bc-i-hotgirls:before {
    content: "\ea94"
}

.bc-i-mythology:before {
    content: "\ea95"
}

.bc-i-fairytaleslots:before {
    content: "\ea96"
}

.bc-i-heroesandvillains:before {
    content: "\ea97"
}

.bc-i-filmslot:before {
    content: "\ea98"
}

.bc-i-animalslots:before {
    content: "\ea99"
}

.bc-i-adventure:before {
    content: "\ea9a"
}

.bc-i-musicslots:before {
    content: "\ea9b"
}

.bc-i-journey:before {
    content: "\ea8b"
}

.bc-i-womeninslots:before {
    content: "\ea8a"
}

.bc-i-momo-sfavourites:before {
    content: "\ea87"
}

.bc-i-buddy-transfer:before {
    content: "\ea84"
}

.bc-i-141419:before,.bc-i-pascal:before {
    content: "\ea83"
}

.bc-i-141415:before,.bc-i-magic-dice:before {
    content: "\ea82"
}

.bc-i-crypto:before {
    content: "\ea7b"
}

.bc-i-bank-card:before {
    content: "\ea7c"
}

.bc-i-bank:before {
    content: "\ea7d"
}

.bc-i-agent:before {
    content: "\ea7e"
}

.bc-i-voucher:before {
    content: "\ea7f"
}

.bc-i-qr:before {
    content: "\ea80"
}

.bc-i-100lines:before {
    content: "\ea77"
}

.bc-i-minigames:before {
    content: "\ea74"
}

.bc-i-skillgames:before {
    content: "\ea75"
}

.bc-i-gameShows:before {
    content: "\ea73"
}

.bc-i-400038601:before,.bc-i-jetx:before {
    content: "\ea79"
}

.bc-i-141422:before,.bc-i-fighters-xxx:before {
    content: "\ea6f"
}

.bc-i-one-block:before {
    content: "\ea6d"
}

.bc-i-two-block:before,.bc-i-two-column:before {
    content: "\ea6e"
}

.bc-i-help:before {
    content: "\ea6b"
}

.bc-i-about:before {
    content: "\ea6c"
}

.bc-i-holdthejackpot:before {
    content: "\ea60"
}

.bc-i-150026:before,.bc-i-BattleShip:before,.bc-i-Ship:before {
    content: "\e94d"
}

.bc-i-multiview:before {
    content: "\ea54"
}

.bc-i-window-two:before {
    content: "\ea55"
}

.bc-i-window-one:before {
    content: "\ea56"
}

.bc-i-141418:before,.bc-i-dice:before {
    content: "\ea52"
}

.bc-i-LuckyEnvelopes:before {
    content: "\ea4f"
}

.bc-i-508:before,.bc-i-okey:before {
    content: "\ea3c"
}

.bc-i-141416:before,.bc-i-4000237:before,.bc-i-fishing:before {
    content: "\ea3b"
}

.bc-i-4000287:before,.bc-i-4000508:before,.bc-i-bettinggames:before {
    content: "\ea39"
}

.bc-i-525:before,.bc-i-predictor:before {
    content: "\ea36"
}

.bc-i-location:before {
    content: "\ea33"
}

.bc-i-squad:before {
    content: "\ea2e"
}

.bc-i-refer-to-friend:before {
    content: "\ea4d"
}

.bc-i-standings:before {
    content: "\ea2f"
}

.bc-i-referee:before {
    content: "\ea27"
}

.bc-i-live-chat:before {
    content: "\ea1b"
}

.bc-i-live-chat-v1:before {
    content: "\ea64"
}

.bc-i-user:before {
    content: "\e919"
}

.bc-i-matches:before {
    content: "\ea30"
}

.bc-i-stadium:before {
    content: "\ea28"
}

.bc-i-141414:before,.bc-i-x50-wheel:before {
    content: "\ea2d"
}

.bc-i-reeloffortune:before {
    content: "\ea1f"
}

.bc-i-wonderwheel:before {
    content: "\ea0d"
}

.bc-i-instantwin:before {
    content: "\ea1c"
}

.bc-i-jackpotmania:before {
    content: "\ea17"
}

.bc-i-mustdropjackpots:before {
    content: "\ea18"
}

.bc-i-fireblazejackpots:before {
    content: "\ea19"
}

.bc-i-valentine:before,.bc-i-valentinesdayslots:before {
    content: "\ea16"
}

.bc-i-achievementsslots:before {
    content: "\ea15"
}

.bc-i-arcadegames:before {
    content: "\ea14"
}

.bc-i-pinnacle:before {
    content: "\ea11"
}

.bc-i-e-sport:before,.bc-i-E-sports:before,.bc-i-ElectronicSports:before,.bc-i-esports:before {
    content: "\ea10"
}

.bc-i-E-sports-2:before {
    content: "\ead4"
}

.bc-i-mega-jackpot:before {
    content: "\e9fe"
}

.bc-i-major-jackpot:before {
    content: "\e9ff"
}

.bc-i-minor-jackpot:before {
    content: "\ea00"
}

.bc-i-mini-jackpot:before {
    content: "\ea01"
}

.bc-i-tvgames:before {
    content: "\ea76"
}

.bc-i-tv-games:before,.bc-i-tv:before {
    content: "\e9fc"
}

.bc-i-tv-2:before {
    content: "\ead5"
}

.bc-i-copy:before {
    content: "\e9fd"
}

.bc-i-4000241:before,.bc-i-authentication:before,.bc-i-two-factor-authentication:before {
    content: "\ea3a"
}

.bc-i-not-verified:before {
    content: "\e9fa"
}

.bc-i-verified:before {
    content: "\e9fb"
}

.bc-i-christmasslots:before {
    content: "\e9f9"
}

.bc-i-halloween2018:before,.bc-i-halloween:before {
    content: "\e9f5"
}

.bc-i-link:before {
    content: "\e9f4"
}

.bc-i-Yachting:before {
    content: "\e66e"
}

.bc-i-bet-cloud:before {
    content: "\e9f2"
}

.bc-i-world:before {
    content: "\e9f1"
}

.bc-i-ageofthegods:before {
    content: "\e9f0"
}

.bc-i-demigods:before {
    content: "\e939"
}

.bc-i-exclusive:before {
    content: "\e9e9"
}

.bc-i-non-stopdrop:before {
    content: "\eab5"
}

.bc-i-drops-wins:before,.bc-i-drops-winsslots:before {
    content: "\e9ea"
}

.bc-i-summerheatpromo:before {
    content: "\eaad"
}

.bc-i-suggested-bets:before {
    content: "\e9e8"
}

.bc-i-results:before {
    content: "\e9de"
}

.bc-i-results-1:before {
    content: "\eaba"
}

.bc-i-results-2:before {
    content: "\ead6"
}

.bc-i-exclamation:before {
    content: "\e9f3"
}

.bc-i-remove-round:before {
    content: "\eaa7"
}

.bc-i-cancel-circle:before {
    content: "\e9e0"
}

.bc-i-check-circle:before {
    content: "\e9e1"
}

.bc-i-unsettled:before {
    content: "\e95c"
}

.bc-i-watch:before {
    content: "\e9df"
}

.bc-i-verify-account:before {
    content: "\e9c4"
}

.bc-i-BestNetentSlots:before {
    content: "\e9dc"
}

.bc-i-filter:before {
    content: "\e9d9"
}

.bc-i-fantasy-sport:before,.bc-i-fantasy:before {
    content: "\e9d6"
}

.bc-i-goal:before {
    content: "\e9d3"
}

.bc-i-substitution:before {
    content: "\e9d4"
}

.bc-i-clear:before {
    content: "\e9b3"
}

.bc-i-buybonus:before {
    content: "\e9b2"
}

.bc-i-stats:before {
    content: "\e9b0"
}

.bc-i-deposit:before {
    content: "\eaac"
}

.bc-i-withdraw:before {
    content: "\e9ce"
}

.bc-i-withdraws:before {
    content: "\e9cd"
}

.bc-i-sent:before {
    content: "\e9cb"
}

.bc-i-inbox:before {
    content: "\e9cc"
}

.bc-i-open-bets:before {
    content: "\e9c2"
}

.bc-i-bet-history:before {
    content: "\e9c3"
}

.bc-i-self-exclusion:before {
    content: "\e9c5"
}

.bc-i-time-out:before {
    content: "\e9c6"
}

.bc-i-change-password:before {
    content: "\e9c7"
}

.bc-i-game-stop:before {
    content: "\e9c9"
}

.bc-i-all-games:before,.bc-i-all:before {
    content: "\e92e"
}

.bc-i-coupon:before {
    content: "\e9ef"
}

.bc-i-promo-code:before {
    content: "\e9c8"
}

.bc-i-apply-promo-code:before {
    content: "\e9be"
}

.bc-i-gift-received:before {
    content: "\ea70"
}

.bc-i-bonus-casino:before {
    content: "\e9bf"
}

.bc-i-bonus-sportsbook:before {
    content: "\e9c0"
}

.bc-i-bonus:before {
    content: "\e9da"
}

.bc-i-bonus-1:before {
    content: "\eabb"
}

.bc-i-bonus-request:before {
    content: "\ea50"
}

.bc-i-exchange-shop:before {
    content: "\e9d1"
}

.bc-i-loyalty-points:before {
    content: "\e9c1"
}

.bc-i-fishing-hunting:before {
    content: "\e9bc"
}

.bc-i-irishluck:before {
    content: "\e9bd"
}

.bc-i-betconstruct:before,.bc-i-vgs:before {
    content: "\e9f6"
}

.bc-i-net:before {
    content: "\e9b5"
}

.bc-i-psn:before {
    content: "\e9b6"
}

.bc-i-fgs:before {
    content: "\e9b7"
}

.bc-i-apg:before {
    content: "\e9b8"
}

.bc-i-rtg:before {
    content: "\e9b9"
}

.bc-i-egt:before {
    content: "\e9ba"
}

.bc-i-dlv:before {
    content: "\e9bb"
}

.bc-i-ppg:before {
    content: "\ea1e"
}

.bc-i-hbn:before {
    content: "\ea86"
}

.bc-i-125157:before,.bc-i-betfights:before {
    content: "\ea05"
}

.bc-i-500:before,.bc-i-pasoor:before {
    content: "\ea06"
}

.bc-i-504:before,.bc-i-hokm:before {
    content: "\ea07"
}

.bc-i-hokm-1:before {
    content: "\eabc"
}

.bc-i-5000001:before,.bc-i-striker:before {
    content: "\ea03"
}

.bc-i-5000000:before,.bc-i-blast:before {
    content: "\e9b4"
}

.bc-i-14000:before,.bc-i-financial:before {
    content: "\e984"
}

.bc-i-141413:before,.bc-i-monti:before {
    content: "\ea20"
}

.bc-i-5000002:before,.bc-i-crash:before {
    content: "\ea21"
}

.bc-i-102016:before,.bc-i-plinko:before {
    content: "\ea12"
}

.bc-i-27117:before,.bc-i-mines:before {
    content: "\ea13"
}

.bc-i-45:before,.bc-i-vr-live-dealer:before {
    content: "\e987"
}

.bc-i-400040631:before,.bc-i-empirelottery:before {
    content: "\eab6"
}

.bc-i-3400000:before,.bc-i-bitupdown:before {
    content: "\eab7"
}

.bc-i-570:before,.bc-i-aliensmasters:before {
    content: "\eab8"
}

.bc-i-334:before,.bc-i-big-hilo:before,.bc-i-hi-lo:before {
    content: "\e983"
}

.bc-i-131315:before,.bc-i-crystal-crush:before {
    content: "\ea40"
}

.bc-i-lightning-dice:before {
    content: "\e97a"
}

.bc-i-sic-bo:before {
    content: "\e97b"
}

.bc-i-buffalo-blitz-live-slots:before {
    content: "\e97c"
}

.bc-i-spin-a-win:before {
    content: "\e97d"
}

.bc-i-super-sic-bo:before {
    content: "\e97e"
}

.bc-i-deal-or-no-deal:before {
    content: "\e981"
}

.bc-i-monopoly-live:before {
    content: "\e982"
}

.bc-i-11:before,.bc-i-backgammon:before {
    content: "\e900"
}

.bc-i-88:before,.bc-i-checker:before,.bc-i-checkers:before {
    content: "\e902"
}

.bc-i-29:before,.bc-i-chingachoong:before {
    content: "\e903"
}

.bc-i-116:before,.bc-i-ogwil:before {
    content: "\e904"
}

.bc-i-771:before,.bc-i-power-ball:before {
    content: "\ea29"
}

.bc-i-152000:before,.bc-i-poolsbetting:before {
    content: "\ea2b"
}

.bc-i-9010:before,.bc-i-zeppelin:before {
    content: "\ea2c"
}

.bc-i-poker-card:before {
    content: "\e905"
}

.bc-i-24:before,.bc-i-russian-roulette:before {
    content: "\e906"
}

.bc-i-1181:before,.bc-i-talisman:before {
    content: "\e907"
}

.bc-i-3539:before,.bc-i-zonk:before {
    content: "\e908"
}

.bc-i-28:before,.bc-i-poker:before {
    content: "\e960"
}

.bc-i-poker-1:before {
    content: "\eabd"
}

.bc-i-poker-2:before {
    content: "\eade"
}

.bc-i-live:before {
    content: "\e963"
}

.bc-i-live-1:before {
    content: "\ead0"
}

.bc-i-live-2:before {
    content: "\ead7"
}

.bc-i-live-v1:before {
    content: "\ea67"
}

.bc-i-chain-bet:before {
    content: "\e9ae"
}

.bc-i-system-bet:before {
    content: "\e964"
}

.bc-i-multiple-bet:before {
    content: "\e966"
}

.bc-i-single-bet:before {
    content: "\e967"
}

.bc-i-prematch:before {
    content: "\e965"
}

.bc-i-prematch-1:before {
    content: "\ead1"
}

.bc-i-prematch-2:before {
    content: "\ead8"
}

.bc-i-prematch-v1:before {
    content: "\ea65"
}

.bc-i-bet-limits:before,.bc-i-login-limit:before,.bc-i-placed:before,.bc-i-time:before {
    content: "\e8b5"
}

.bc-i-declined:before,.bc-i-remove-circle:before {
    content: "\e9db"
}

.bc-i-lost:before {
    content: "\e95d"
}

.bc-i-won:before {
    content: "\e95e"
}

.bc-i-refresh:before {
    content: "\ea02"
}

.bc-i-pending:before {
    content: "\eaf3"
}

.bc-i-repeat:before {
    content: "\ea62"
}

.bc-i-exchange:before {
    content: "\e9d8"
}

.bc-i-on-hold:before {
    content: "\ea5d"
}

.bc-i-won-return:before {
    content: "\e9dd"
}

.bc-i-lost-return:before {
    content: "\ea3f"
}

.bc-i-returned:before {
    content: "\e969"
}

.bc-i-history:before {
    content: "\e90b"
}

.bc-i-cashed-out:before {
    content: "\e95f"
}

.bc-i-cash:before {
    content: "\ea81"
}

.bc-i-boost:before {
    content: "\e9d7"
}

.bc-i-info:before {
    content: "\e926"
}

.bc-i-player-info:before {
    content: "\e938"
}

.bc-i-add-circle:before {
    content: "\e9ca"
}

.bc-i-transfer:before {
    content: "\e9cf"
}

.bc-i-round-arrow-left:before {
    content: "\e956"
}

.bc-i-round-arrow-right:before {
    content: "\e957"
}

.bc-i-slots:before {
    content: "\e955"
}

.bc-i-slots-v1:before {
    content: "\ea66"
}

.bc-i-sports:before {
    content: "\e953"
}

.bc-i-jackpot:before {
    content: "\e954"
}

.bc-i-eye-hidden:before {
    content: "\e909"
}

.bc-i-download:before {
    content: "\ea0a"
}

.bc-i-upload:before {
    content: "\e90a"
}

.bc-i-star:before {
    content: "\e93a"
}

.bc-i-favorite:before {
    content: "\e93b"
}

.bc-i-upcoming:before {
    content: "\e9af"
}

.bc-i-datepicker:before {
    content: "\e93e"
}

.bc-i-dragontiger:before {
    content: "\e93f"
}

.bc-i-324:before,.bc-i-150024:before,.bc-i-keno:before,.bc-i-livekeno:before {
    content: "\e940"
}

.bc-i-rouletteftv:before {
    content: "\e942"
}

.bc-i-liveroulette:before {
    content: "\e950"
}

.bc-i-dreamcatcher:before {
    content: "\e947"
}

.bc-i-liveholdem:before {
    content: "\e948"
}

.bc-i-live-casino:before,.bc-i-livecasino:before {
    content: "\e945"
}

.bc-i-live-casino-1:before {
    content: "\eabe"
}

.bc-i-live-casino-2:before {
    content: "\ead9"
}

.bc-i-asianGames:before {
    content: "\ea71"
}

.bc-i-44:before,.bc-i-chinese-poker:before,.bc-i-open-chinese-poker:before {
    content: "\e985"
}

.bc-i-tablegames:before {
    content: "\e99b"
}

.bc-i-cards:before {
    content: "\ea78"
}

.bc-i-side-bet-city:before {
    content: "\e97f"
}

.bc-i-football-studio:before {
    content: "\e980"
}

.bc-i-blackjackftv:before {
    content: "\e943"
}

.bc-i-baccaratftv:before {
    content: "\e944"
}

.bc-i-supersixbaccarat:before {
    content: "\e946"
}

.bc-i-10:before,.bc-i-belote:before {
    content: "\e901"
}

.bc-i-livepoker:before {
    content: "\e949"
}

.bc-i-caribbeanstudpoker:before {
    content: "\e94b"
}

.bc-i-threecardpoker:before {
    content: "\e94c"
}

.bc-i-baccarat:before,.bc-i-livebaccarat:before {
    content: "\e951"
}

.bc-i-blackjack:before,.bc-i-liveblackjack:before {
    content: "\e952"
}

.bc-i-olympic:before {
    content: "\ea42"
}

.bc-i-400037034:before,.bc-i-Betting-bet:before,.bc-i-populargames:before,.bc-i-tournament:before,.bc-i-VirtualFootballLeague:before {
    content: "\e93d"
}

.bc-i-tournament-2:before {
    content: "\eada"
}

.bc-i-casino-tournament-1:before {
    content: "\eabf"
}

.bc-i-tournament-1:before {
    content: "\eac0"
}

.bc-i-counter-offer:before {
    content: "\e958"
}

.bc-i-freebet:before {
    content: "\e959"
}

.bc-i-profit-boost:before {
    content: "\e95a"
}

.bc-i-superbet:before {
    content: "\e95b"
}

.bc-i-gmail:before {
    content: "\eaa2"
}

.bc-i-ok:before {
    content: "\e968"
}

.bc-i-tumblr:before {
    content: "\ea89"
}

.bc-i-blog:before {
    content: "\ea63"
}

.bc-i-twitch:before {
    content: "\ea85"
}

.bc-i-reddit:before {
    content: "\ea5f"
}

.bc-i-pinterest:before {
    content: "\ea5e"
}

.bc-i-tiktok:before {
    content: "\ea5c"
}

.bc-i-whatsapp:before {
    content: "\ea4c"
}

.bc-i-vk:before {
    content: "\ea2a"
}

.bc-i-youtube:before {
    content: "\e9f7"
}

.bc-i-instagram:before {
    content: "\e9e3"
}

.bc-i-telegram:before {
    content: "\e961"
}

.bc-i-facebook:before {
    content: "\e913"
}

.bc-i-twitter:before {
    content: "\e915"
}

.bc-i-viber:before {
    content: "\ea31"
}

.bc-i-skype:before {
    content: "\eab0"
}

.bc-i-yellow-card:before {
    content: "\e9d2"
}

.bc-i-corner:before {
    content: "\e921"
}

.bc-i-chat:before {
    content: "\e94a"
}

.bc-i-message:before {
    content: "\e934"
}

.bc-i-balance-management:before {
    content: "\e92d"
}

.bc-i-topslots:before {
    content: "\ea0f"
}

.bc-i-megaways:before {
    content: "\e9b1"
}

.bc-i-turkishTables:before {
    content: "\ea72"
}

.bc-i-videopoker:before {
    content: "\e99c"
}

.bc-i-euro2020slots:before {
    content: "\ea32"
}

.bc-i-casualgames:before {
    content: "\e970"
}

.bc-i-new1:before {
    content: "\e971"
}

.bc-i-scratchgames:before {
    content: "\e972"
}

.bc-i-jackpots:before {
    content: "\e976"
}

.bc-i-jackpots-1:before {
    content: "\eac1"
}

.bc-i-jackpots-2:before {
    content: "\eadb"
}

.bc-i-150022:before,.bc-i-150023:before,.bc-i-420072:before,.bc-i-roulette:before {
    content: "\e986"
}

.bc-i-videoslots:before {
    content: "\e96d"
}

.bc-i-cardgames:before {
    content: "\ea34"
}

.bc-i-virtualbetting:before {
    content: "\e977"
}

.bc-i-virtualbetting-1:before {
    content: "\eac2"
}

.bc-i-virtualbetting-2:before {
    content: "\eadc"
}

.bc-i-tacktics:before {
    content: "\e935"
}

.bc-i-betslip:before {
    content: "\e936"
}

.bc-i-lock:before {
    content: "\e930"
}

.bc-i-frozen:before {
    content: "\eaab"
}

.bc-i-cashback-cashout:before {
    content: "\e9ee"
}

.bc-i-money-dollar:before {
    content: "\e92f"
}

.bc-i-paper-dollar:before {
    content: "\e92b"
}

.bc-i-circle-dollar:before {
    content: "\e92c"
}

.bc-i-logout:before {
    content: "\e92a"
}

.bc-i-edit:before {
    content: "\e928"
}

.bc-i-attention:before {
    content: "\e927"
}

.bc-i-stream:before {
    content: "\e922"
}

.bc-i-card-football:before {
    content: "\e923"
}

.bc-i-players:before {
    content: "\e937"
}

.bc-i-team-t-shirt:before {
    content: "\e925"
}

.bc-i-graph:before {
    content: "\e91f"
}

.bc-i-half-time:before {
    content: "\e924"
}

.bc-i-head-t-head:before {
    content: "\e920"
}

.bc-i-field-soccer:before {
    content: "\e91a"
}

.bc-i-result:before {
    content: "\e91b"
}

.bc-i-field-basketball:before {
    content: "\e91c"
}

.bc-i-block-to-right:before {
    content: "\ea68"
}

.bc-i-block-to-left:before {
    content: "\ea69"
}

.bc-i-burger-2:before {
    content: "\e91d"
}

.bc-i-burger-menu:before,.bc-i-burger:before {
    content: "\e93c"
}

.bc-i-reorder-dots:before {
    content: "\ea0b"
}

.bc-i-apps:before {
    content: "\e5c3"
}

.bc-i-search:before {
    content: "\e91e"
}

.bc-i-wallet:before {
    content: "\e918"
}

.bc-i-delete:before {
    content: "\e929"
}

.bc-i-streaming:before {
    content: "\e916"
}

.bc-i-statistics:before {
    content: "\e917"
}

.bc-i-linkedin:before {
    content: "\e914"
}

.bc-i-android:before {
    content: "\e910"
}

.bc-i-app-store:before {
    content: "\e911"
}

.bc-i-mobile:before {
    content: "\e912"
}

.bc-i-applications:before {
    content: "\ea6a"
}

.bc-i-draw:before {
    content: "\e90f"
}

.bc-i-eye:before {
    content: "\e90c"
}

.bc-i-eye-no-fill:before {
    content: "\e931"
}

.bc-i-call:before {
    content: "\e932"
}

.bc-i-casino:before {
    content: "\e933"
}

.bc-i-casino-1:before {
    content: "\eac3"
}

.bc-i-casino-2:before {
    content: "\eadd"
}

.bc-i-notification:before {
    content: "\e94e"
}

.bc-i-promotion:before {
    content: "\e94f"
}

.bc-i-settings:before {
    content: "\e96a"
}

.bc-i-swatches:before {
    content: "\e96b"
}

.bc-i-play-circle:before {
    content: "\ea22"
}

.bc-i-pause:before {
    content: "\e034"
}

.bc-i-play:before {
    content: "\e037"
}

.bc-i-stop:before {
    content: "\e047"
}

.bc-i-checked:before {
    content: "\e5ca"
}

.bc-i-close-with:before {
    content: "\ea88"
}

.bc-i-open-with:before {
    content: "\e89f"
}

.bc-i-settings1:before {
    content: "\e96c"
}

.bc-i-config:before {
    content: "\e869"
}

.bc-i-duplicate:before {
    content: "\e3e0"
}

.bc-i-add:before {
    content: "\e145"
}

.bc-i-remove:before {
    content: "\e15b"
}

.bc-i-radio-selected:before {
    content: "\e90d"
}

.bc-i-radio-unselected:before {
    content: "\e90e"
}

.bc-i-check-box-checked:before {
    content: "\e834"
}

.bc-i-check-box-unchecked:before {
    content: "\e835"
}

.bc-i-double-arrow-top:before {
    content: "\ea61"
}

.bc-i-double-arrow-bottom:before {
    content: "\e9d0"
}

.bc-i-compare-arrows:before {
    content: "\ea04"
}

.bc-i-arrow-to:before {
    content: "\e941"
}

.bc-i-arrow-to-left:before {
    content: "\ea23"
}

.bc-i-arrow-to-right:before {
    content: "\ea24"
}

.bc-i-arrow-back:before {
    content: "\e5c4"
}

.bc-i-arrow-forward:before {
    content: "\e5c8"
}

.bc-i-arrow-downward:before {
    content: "\e5db"
}

.bc-i-arrow-upward:before {
    content: "\e5d8"
}

.bc-i-small-arrow-left:before {
    content: "\e314"
}

.bc-i-small-arrow-right:before {
    content: "\e315"
}

.bc-i-small-arrow-down:before {
    content: "\e5cf"
}

.bc-i-small-arrow-up:before {
    content: "\e5ce"
}

.bc-i-arrow-down:before {
    content: "\e9e6"
}

.bc-i-arrow-up:before {
    content: "\e9e7"
}

.bc-i-arrow-drop-down-left:before {
    content: "\e5c5"
}

.bc-i-arrow-drop-down-right:before {
    content: "\e5c6"
}

.bc-i-arrow-drop-down-bottom:before {
    content: "\e5c7"
}

.bc-i-arrow-drop-down-up:before {
    content: "\e5c9"
}

.bc-i-close-remove:before {
    content: "\e5cd"
}

.bc-i-lightning:before {
    content: "\ea25"
}

.bc-i-thunderstorm:before {
    content: "\ea4e"
}

.bc-i-FlashGames:before,.bc-i-new:before {
    content: "\ea0c"
}

.bc-i-Slot777:before {
    content: "\ea0e"
}

.bc-i-lotterygames:before {
    content: "\e9a4"
}

.bc-i-VideoBingo:before,.bc-i-videobingo:before {
    content: "\e9a2"
}

.bc-i-othergames:before,.bc-i-others:before {
    content: "\e99f"
}

.bc-i-vertical-toggle:before {
    content: "\ea1d"
}

.bc-i-new-icon:before {
    content: "\e99d"
}

.bc-i-classic_slots_icon:before,.bc-i-classicslots:before {
    content: "\e988"
}

.bc-i-skill_games:before {
    content: "\e973"
}

.bc-i-topgames:before {
    content: "\e975"
}

.bc-i-SlotMachine:before {
    content: "\e96e"
}

.bc-i-Vip:before {
    content: "\e96f"
}

.bc-i-Wheel-of-dice:before {
    content: "\e974"
}

.bc-i-LiveDraw:before {
    content: "\77"
}

.bc-i-Search:before {
    content: "\e6d4"
}

.bc-i-star-radius:before {
    content: "\ea26"
}

.bc-i-Star:before {
    content: "\e978"
}

.bc-i-Star2:before {
    content: "\e979"
}

.bc-i-dailyjackpots:before {
    content: "\ea51"
}

.bc-i-Favorite-2:before {
    content: "\e6ac"
}

.bc-i-Favorite:before {
    content: "\e6df"
}

.bc-i-Favorite-outline:before {
    content: "\e6e1"
}

.bc-i-324121:before,.bc-i-powerkeno:before {
    content: "\ea57"
}

.bc-i-wagering-bonus:before {
    content: "\eac4"
}

.bc-i-3301632:before,.bc-i-arcade:before {
    content: "\eac5"
}

.bc-i-420014053:before,.bc-i-gogoBanana:before {
    content: "\eac6"
}

.bc-i-420014058:before,.bc-i-diamondQuest:before {
    content: "\eac7"
}

.bc-i-420014051:before,.bc-i-plinko1:before {
    content: "\eac8"
}

.bc-i-420014052:before,.bc-i-magicIdol:before {
    content: "\eac9"
}

.bc-i-141417:before,.bc-i-nonStopRoulette:before {
    content: "\eaca"
}

.bc-i-141426:before,.bc-i-catchMe:before {
    content: "\eacb"
}

.bc-i-161616:before,.bc-i-nonStopBlackJack:before {
    content: "\eacc"
}

.bc-i-141425:before,.bc-i-dreamWheel:before {
    content: "\eacd"
}

.bc-i-141427:before,.bc-i-nonStopBaccarat:before {
    content: "\eace"
}

.bc-i-141432:before,.bc-i-turboBaccarat:before {
    content: "\eacf"
}

.bc-i-welcome:before {
    content: "\eadf"
}

.bc-i-calendar:before,.bc-i-today-bets:before {
    content: "\eae0"
}

.bc-i-fruits:before {
    content: "\eae2"
}

.bc-i-books:before {
    content: "\eae3"
}

.bc-i-wagerslots:before {
    content: "\eae4"
}

.bc-i-egypt:before {
    content: "\eae5"
}

.bc-i-popular:before {
    content: "\eae6"
}

.bc-i-400037044:before,.bc-i-footballStreakChampions:before {
    content: "\eae7"
}

.bc-i-400037043:before,.bc-i-virtualFootballLeague:before {
    content: "\eae8"
}

.bc-i-40009992:before,.bc-i-greyhoundsStreak:before {
    content: "\eae9"
}

.bc-i-400037040:before,.bc-i-420015961:before,.bc-i-horsesStreak:before {
    content: "\eaea"
}

.bc-i-40009991:before,.bc-i-420015962:before,.bc-i-420015963:before,.bc-i-footballPenaltyDuel:before {
    content: "\eaeb"
}

.bc-i-400037060:before,.bc-i-footballCupWorld:before {
    content: "\eaec"
}

.bc-i-scratch-card:before {
    content: "\eab4"
}

.bc-i-Print:before {
    content: "\eaed"
}

.bc-i-worldcup2022:before {
    content: "\eaee"
}

.bc-i-icons-feed-throwin:before,.bc-i-throw_in:before {
    content: "\eaef"
}

.bc-i-icons-feed-offside:before,.bc-i-offside:before {
    content: "\eaf0"
}

.bc-i-foul:before {
    content: "\eaf1"
}

.bc-i-Pelota:before {
    content: "\eaf2"
}

.bc-i-newyear:before {
    content: "\eaf4"
}

.bc-i-420014070:before,.bc-i-electric-crash:before {
    content: "\eaf5"
}

.bc-i-33060318:before,.bc-i-turbo-belote:before {
    content: "\eaf6"
}

.bc-i-420014065:before,.bc-i-hilo:before {
    content: "\eaf7"
}

.bc-i-141428:before,.bc-i-double-wheel:before {
    content: "\eaf8"
}

.bc-i-141430:before,.bc-i-drift:before {
    content: "\eaf9"
}

.bc-i-specialgames:before {
    content: "\eafa"
}

.bc-i-buy-bonus-slots:before {
    content: "\eafb"
}

.bc-i-medium:before {
    content: "\eafc"
}

.bc-i-151516:before,.bc-i-lucky-lotto:before {
    content: "\eafd"
}

.bc-i-33060324:before,.bc-i-mines1:before {
    content: "\eafe"
}

.bc-i-420014075:before,.bc-i-baccarat1:before {
    content: "\eaff"
}

.bc-i-Multi:before {
    content: "\eb00"
}

.bc-i-12500:before,.bc-i-12501:before,.bc-i-12502:before,.bc-i-12503:before,.bc-i-12507:before,.bc-i-12508:before,.bc-i-420015766:before,.bc-i-keno1:before {
    content: "\eb01"
}

.bc-i-12505:before,.bc-i-pokerTH:before {
    content: "\eb02"
}

.bc-i-12509:before,.bc-i-12511:before,.bc-i-12512:before,.bc-i-12513:before,.bc-i-12514:before,.bc-i-roulette1:before {
    content: "\eb03"
}

.bc-i-12504:before,.bc-i-redlottery:before {
    content: "\eb04"
}

.bc-i-420015794:before,.bc-i-lotto-7-49:before {
    content: "\eb05"
}

.bc-i-420015793:before,.bc-i-lotto-6-42:before {
    content: "\eb06"
}

.bc-i-420015792:before,.bc-i-lotto-5-36:before {
    content: "\eb07"
}

.bc-i-features:before {
    content: "\eb08"
}

.bc-i-extrachance:before {
    content: "\eb09"
}

.bc-i-occupation:before {
    content: "\eb0a"
}

.bc-i-show-all-categories:before {
    content: "\eb0b"
}

.bc-i-3606:before,.bc-i-aviatrix:before {
    content: "\eb0c"
}

.bc-i-420015509:before,.bc-i-JacksOrBetter:before {
    content: "\eb0d"
}

.bc-i-33060326:before,.bc-i-nonStopMatch:before {
    content: "\eb0e"
}

.bc-i-33060314:before,.bc-i-OddBall:before {
    content: "\eb0f"
}

.bc-i-33060319:before,.bc-i-champion-challenge:before {
    content: "\eb10"
}

.bc-i-33060316:before,.bc-i-turbo-blackjack:before {
    content: "\eb11"
}

.bc-i-400043028:before,.bc-i-spaceman:before {
    content: "\eb12"
}

.bc-i-BTC:before {
    content: "\eb13"
}

.bc-i-ETH:before {
    content: "\eb14"
}

.bc-i-TUS:before {
    content: "\eb15"
}

.bc-i-LTC:before {
    content: "\eb16"
}

.bc-i-FTN:before {
    content: "\eb17"
}

.bc-i-420015960:before,.bc-i-dog6:before {
    content: "\eb19"
}

.bc-i-achievement:before {
    content: "\eb18"
}

.bc-i-crashgamesur:before {
    content: "\eb1a"
}

.bc-i-33060327:before,.bc-i-blast1:before {
    content: "\eb1b"
}

.bc-i-33060325:before,.bc-i-crazyWorm:before {
    content: "\eb1c"
}

.bc-i-420015275:before,.bc-i-stairs:before {
    content: "\eb1d"
}

.bc-i-Diia:before {
    content: "\eb1e"
}

.afghanistan {
    background-position: 0 .3460207612%
}

.africa {
    background-position: 0 .6920415225%
}

.aland {
    background-position: 0 1.0380622837%
}

.albania {
    background-position: 0 1.384083045%
}

.alderney {
    background-position: 0 1.7301038062%
}

.algeria {
    background-position: 0 2.0761245675%
}

.americas {
    background-position: 0 2.4221453287%
}

.americansamoa {
    background-position: 0 2.76816609%
}

.andorra {
    background-position: 0 3.1141868512%
}

.angola {
    background-position: 0 3.4602076125%
}

.anguilla {
    background-position: 0 3.8062283737%
}

.antiguaandbarbuda {
    background-position: 0 4.1522491349%
}

.arableague {
    background-position: 0 4.4982698962%
}

.argentina {
    background-position: 0 4.8442906574%
}

.armenia {
    background-position: 0 5.1903114187%
}

.aruba {
    background-position: 0 5.5363321799%
}

.asia {
    background-position: 0 5.8823529412%
}

.australia {
    background-position: 0 6.2283737024%
}

.austria {
    background-position: 0 6.5743944637%
}

.azerbaijan {
    background-position: 0 6.9204152249%
}

.bahamas {
    background-position: 0 7.2664359862%
}

.bahrain {
    background-position: 0 7.6124567474%
}

.bangladesh {
    background-position: 0 7.9584775087%
}

.barbados {
    background-position: 0 8.3044982699%
}

.basquecountry {
    background-position: 0 8.6505190311%
}

.belarus {
    background-position: 0 8.9965397924%
}

.belgium {
    background-position: 0 9.3425605536%
}

.belize {
    background-position: 0 9.6885813149%
}

.benin {
    background-position: 0 10.0346020761%
}

.bermuda {
    background-position: 0 10.3806228374%
}

.bhutan {
    background-position: 0 10.7266435986%
}

.bolivia {
    background-position: 0 11.0726643599%
}

.bonairesinteustatiusandsaba {
    background-position: 0 11.4186851211%
}

.bosniaherzegovina {
    background-position: 0 11.7647058824%
}

.botswana {
    background-position: 0 12.1107266436%
}

.bouvet {
    background-position: 0 12.4567474048%
}

.brazil {
    background-position: 0 12.8027681661%
}

.britishindianoceanterritory {
    background-position: 0 13.1487889273%
}

.brunei {
    background-position: 0 13.4948096886%
}

.bulgaria {
    background-position: 0 13.8408304498%
}

.burkinafaso {
    background-position: 0 14.1868512111%
}

.burundi {
    background-position: 0 14.5328719723%
}

.cambodia {
    background-position: 0 14.8788927336%
}

.cameroon {
    background-position: 0 15.2249134948%
}

.canada {
    background-position: 0 15.5709342561%
}

.capeverde {
    background-position: 0 15.9169550173%
}

.caricom {
    background-position: 0 16.2629757785%
}

.catalonia {
    background-position: 0 16.6089965398%
}

.caymanislands {
    background-position: 0 16.955017301%
}

.centralafricanrepublic {
    background-position: 0 17.3010380623%
}

.chad {
    background-position: 0 17.6470588235%
}

.chile {
    background-position: 0 17.9930795848%
}

.china {
    background-position: 0 18.339100346%
}

.chinesetaipei {
    background-position: 0 18.6851211073%
}

.christmas {
    background-position: 0 19.0311418685%
}

.cis {
    background-position: 0 19.3771626298%
}

.cocoskeeling {
    background-position: 0 19.723183391%
}

.colombia {
    background-position: 0 20.0692041522%
}

.commonwealth {
    background-position: 0 20.4152249135%
}

.comoros {
    background-position: 0 20.7612456747%
}

.congobrazzaville {
    background-position: 0 21.107266436%
}

.congokinshasa {
    background-position: 0 21.4532871972%
}

.cookislands {
    background-position: 0 21.7993079585%
}

.costarica {
    background-position: 0 22.1453287197%
}

.cotedivoire {
    background-position: 0 22.491349481%
}

.croatia {
    background-position: 0 22.8373702422%
}

.cuba {
    background-position: 0 23.1833910035%
}

.curacao {
    background-position: 0 23.5294117647%
}

.cyprus {
    background-position: 0 23.875432526%
}

.czechrepublic {
    background-position: 0 24.2214532872%
}

.denmark {
    background-position: 0 24.5674740484%
}

.djibouti {
    background-position: 0 24.9134948097%
}

.dominica {
    background-position: 0 25.2595155709%
}

.dominicanrepublic {
    background-position: 0 25.6055363322%
}

.ecuador {
    background-position: 0 25.9515570934%
}

.egypt {
    background-position: 0 26.2975778547%
}

.elsalvador {
    background-position: 0 26.6435986159%
}

.england {
    background-position: 0 26.9896193772%
}

.equatorialguinea {
    background-position: 0 27.3356401384%
}

.eritrea {
    background-position: 0 27.6816608997%
}

.estonia {
    background-position: 0 28.0276816609%
}

.ethiopia {
    background-position: 0 28.3737024221%
}

.europe {
    background-position: 0 28.7197231834%
}

.falkland {
    background-position: 0 29.0657439446%
}

.fao {
    background-position: 0 29.4117647059%
}

.faroeislands {
    background-position: 0 29.7577854671%
}

.fiji {
    background-position: 0 30.1038062284%
}

.finland {
    background-position: 0 30.4498269896%
}

.france {
    background-position: 0 30.7958477509%
}

.french-guiana {
    background-position: 0 31.1418685121%
}

.frenchsouthernandantarcticlands {
    background-position: 0 31.4878892734%
}

.gabon {
    background-position: 0 31.8339100346%
}

.galicia {
    background-position: 0 32.1799307958%
}

.gambia {
    background-position: 0 32.5259515571%
}

.georgia {
    background-position: 0 32.8719723183%
}

.germany {
    background-position: 0 33.2179930796%
}

.ghana {
    background-position: 0 33.5640138408%
}

.gibraltar {
    background-position: 0 33.9100346021%
}

.greatbritain {
    background-position: 0 34.2560553633%
}

.greece {
    background-position: 0 34.6020761246%
}

.greenland {
    background-position: 0 34.9480968858%
}

.grenada {
    background-position: 0 35.2941176471%
}

.guadeloupe {
    background-position: 0 35.6401384083%
}

.guam {
    background-position: 0 35.9861591696%
}

.guatemala {
    background-position: 0 36.3321799308%
}

.guernsey {
    background-position: 0 36.678200692%
}

.guinea {
    background-position: 0 37.0242214533%
}

.guineabissau {
    background-position: 0 37.3702422145%
}

.guyana {
    background-position: 0 37.7162629758%
}

.haiti {
    background-position: 0 38.062283737%
}

.heardislandandmcdonald {
    background-position: 0 38.4083044983%
}

.honduras {
    background-position: 0 38.7543252595%
}

.hongkong {
    background-position: 0 39.1003460208%
}

.hungary {
    background-position: 0 39.446366782%
}

.iaea {
    background-position: 0 39.7923875433%
}

.iceland {
    background-position: 0 40.1384083045%
}

.iho {
    background-position: 0 40.4844290657%
}

.india {
    background-position: 0 40.830449827%
}

.indonesia {
    background-position: 0 41.1764705882%
}

.international {
    background-position: 0 41.5224913495%
}

.iran {
    background-position: 0 41.8685121107%
}

.iraq {
    background-position: 0 42.214532872%
}

.ireland {
    background-position: 0 42.5605536332%
}

.islamicconference {
    background-position: 0 42.9065743945%
}

.isleofman {
    background-position: 0 43.2525951557%
}

.israel {
    background-position: 0 43.598615917%
}

.italy {
    background-position: 0 43.9446366782%
}

.jamaica {
    background-position: 0 44.2906574394%
}

.japan {
    background-position: 0 44.6366782007%
}

.jersey {
    background-position: 0 44.9826989619%
}

.jordan {
    background-position: 0 45.3287197232%
}

.kazakhstan {
    background-position: 0 45.6747404844%
}

.kenya {
    background-position: 0 46.0207612457%
}

.kiribati {
    background-position: 0 46.3667820069%
}

.kosovo {
    background-position: 0 46.7128027682%
}

.kurdish {
    background-position: 0 47.0588235294%
}

.kuwait {
    background-position: 0 47.4048442907%
}

.kyrgyzstan {
    background-position: 0 47.7508650519%
}

.laos {
    background-position: 0 48.0968858131%
}

.latvia {
    background-position: 0 48.4429065744%
}

.lebanon {
    background-position: 0 48.7889273356%
}

.lesotho {
    background-position: 0 49.1349480969%
}

.liberia {
    background-position: 0 49.4809688581%
}

.libya {
    background-position: 0 49.8269896194%
}

.liechtenshein {
    background-position: 0 50.1730103806%
}

.lithuania {
    background-position: 0 50.5190311419%
}

.luxembourg {
    background-position: 0 50.8650519031%
}

.macau {
    background-position: 0 51.2110726644%
}

.northmacedonia {
    background-position: 0 51.5570934256%
}

.madagascar {
    background-position: 0 51.9031141869%
}

.malawi {
    background-position: 0 52.2491349481%
}

.malaysia {
    background-position: 0 52.5951557093%
}

.maldives {
    background-position: 0 52.9411764706%
}

.mali {
    background-position: 0 53.2871972318%
}

.malta {
    background-position: 0 53.6332179931%
}

.marshallislands {
    background-position: 0 53.9792387543%
}

.martinique {
    background-position: 0 54.3252595156%
}

.mauritania {
    background-position: 0 54.6712802768%
}

.mauritius {
    background-position: 0 55.0173010381%
}

.mayotte {
    background-position: 0 55.3633217993%
}

.mexico {
    background-position: 0 55.7093425606%
}

.micronesia {
    background-position: 0 56.0553633218%
}

.moldova {
    background-position: 0 56.401384083%
}

.monaco {
    background-position: 0 56.7474048443%
}

.mongolia {
    background-position: 0 57.0934256055%
}

.montenegro {
    background-position: 0 57.4394463668%
}

.montserrat {
    background-position: 0 57.785467128%
}

.morocco {
    background-position: 0 58.1314878893%
}

.mozambique {
    background-position: 0 58.4775086505%
}

.myanmar {
    background-position: 0 58.8235294118%
}

.namibia {
    background-position: 0 59.169550173%
}

.nato {
    background-position: 0 59.5155709343%
}

.nauru {
    background-position: 0 59.8615916955%
}

.nepal {
    background-position: 0 60.2076124567%
}

.netherlands {
    background-position: 0 60.553633218%
}

.netherlandsantilles {
    background-position: 0 60.8996539792%
}

.newcaledonia {
    background-position: 0 61.2456747405%
}

.newzealand {
    background-position: 0 61.5916955017%
}

.nicaragua {
    background-position: 0 61.937716263%
}

.niger {
    background-position: 0 62.2837370242%
}

.nigeria {
    background-position: 0 62.6297577855%
}

.niue {
    background-position: 0 62.9757785467%
}

.norfolk {
    background-position: 0 63.321799308%
}

.northamerica {
    background-position: 0 63.6678200692%
}

.northerncyprus {
    background-position: 0 64.0138408304%
}

.northernireland {
    background-position: 0 64.3598615917%
}

.northernmariana {
    background-position: 0 64.7058823529%
}

.northkorea {
    background-position: 0 65.0519031142%
}

.norway {
    background-position: 0 65.3979238754%
}

.oas {
    background-position: 0 65.7439446367%
}

.oceania {
    background-position: 0 66.0899653979%
}

.olimpics {
    background-position: 0 66.4359861592%
}

.oman {
    background-position: 0 66.7820069204%
}

.opec {
    background-position: 0 67.1280276817%
}

.pakistan {
    background-position: 0 67.4740484429%
}

.palau {
    background-position: 0 67.8200692042%
}

.palestine {
    background-position: 0 68.1660899654%
}

.panama {
    background-position: 0 68.5121107266%
}

.papuanewguinea {
    background-position: 0 68.8581314879%
}

.paraguay {
    background-position: 0 69.2041522491%
}

.peru {
    background-position: 0 69.5501730104%
}

.philippines {
    background-position: 0 69.8961937716%
}

.pitcairn {
    background-position: 0 70.2422145329%
}

.poland {
    background-position: 0 70.5882352941%
}

.portugal {
    background-position: 0 70.9342560554%
}

.puertorico {
    background-position: 0 71.2802768166%
}

.qatar {
    background-position: 0 71.6262975779%
}

.quebec {
    background-position: 0 71.9723183391%
}

.redcross {
    background-position: 0 72.3183391003%
}

.reunion {
    background-position: 0 72.6643598616%
}

.romania {
    background-position: 0 73.0103806228%
}

.russia {
    background-position: 0 73.3564013841%
}

.rwanda {
    background-position: 0 73.7024221453%
}

.saintbarthelemy {
    background-position: 0 74.0484429066%
}

.sainthelena {
    background-position: 0 74.3944636678%
}

.saintlucia {
    background-position: 0 74.7404844291%
}

.saintmartin {
    background-position: 0 75.0865051903%
}

.saintpierreandmiquelon {
    background-position: 0 75.4325259516%
}

.samoa {
    background-position: 0 75.7785467128%
}

.sanmarino {
    background-position: 0 76.124567474%
}

.saotomeandprincipe {
    background-position: 0 76.4705882353%
}

.saudiarabia {
    background-position: 0 76.8166089965%
}

.scotland {
    background-position: 0 77.1626297578%
}

.senegal {
    background-position: 0 77.508650519%
}

.serbia {
    background-position: 0 77.8546712803%
}

.seychelles {
    background-position: 0 78.2006920415%
}

.sierraleone {
    background-position: 0 78.5467128028%
}

.singapore {
    background-position: 0 78.892733564%
}

.sintmaarten {
    background-position: 0 79.2387543253%
}

.slovakia {
    background-position: 0 79.5847750865%
}

.slovenia {
    background-position: 0 79.9307958478%
}

.solomonislands {
    background-position: 0 80.276816609%
}

.somalia {
    background-position: 0 80.6228373702%
}

.somaliland {
    background-position: 0 80.9688581315%
}

.southafrica {
    background-position: 0 81.3148788927%
}

.southamerica {
    background-position: 0 81.660899654%
}

.southgeorgiaandsouthsandwich {
    background-position: 0 82.0069204152%
}

.southkorea {
    background-position: 0 82.3529411765%
}

.southsudan {
    background-position: 0 82.6989619377%
}

.spain {
    background-position: 0 83.044982699%
}

.srilanka {
    background-position: 0 83.3910034602%
}

.srlmatches {
    background-position: 0 83.7370242215%
}

.stkittsandnevis {
    background-position: 0 84.0830449827%
}

.stvincentandthegrenadines {
    background-position: 0 84.4290657439%
}

.sudan {
    background-position: 0 84.7750865052%
}

.suriname {
    background-position: 0 85.1211072664%
}

.svalbardandjanmayen {
    background-position: 0 85.4671280277%
}

.swaziland {
    background-position: 0 85.8131487889%
}

.sweden {
    background-position: 0 86.1591695502%
}

.switzerland {
    background-position: 0 86.5051903114%
}

.syria {
    background-position: 0 86.8512110727%
}

.tahiti {
    background-position: 0 87.1972318339%
}

.taiwan {
    background-position: 0 87.5432525952%
}

.tajikistan {
    background-position: 0 87.8892733564%
}

.unitedrepublicoftanzania {
    background-position: 0 88.2352941176%
}

.thailand {
    background-position: 0 88.5813148789%
}

.timorleste {
    background-position: 0 88.9273356401%
}

.togo {
    background-position: 0 89.2733564014%
}

.tokelau {
    background-position: 0 89.6193771626%
}

.tonga {
    background-position: 0 89.9653979239%
}

.trinidadtobago {
    background-position: 0 90.3114186851%
}

.tristandacunha {
    background-position: 0 90.6574394464%
}

.tunisia {
    background-position: 0 91.0034602076%
}

.turkey {
    background-position: 0 91.3494809689%
}

.turkmenistan {
    background-position: 0 91.6955017301%
}

.turksandcaicosislands {
    background-position: 0 92.0415224913%
}

.tuvalu {
    background-position: 0 92.3875432526%
}

.uganda {
    background-position: 0 92.7335640138%
}

.ukraine {
    background-position: 0 93.0795847751%
}

.uae {
    background-position: 0 93.4256055363%
}

.unitedkingdom {
    background-position: 0 93.7716262976%
}

.uruguay {
    background-position: 0 94.1176470588%
}

.usa {
    background-position: 0 94.4636678201%
}

.uzbekistan {
    background-position: 0 94.8096885813%
}

.vanutau {
    background-position: 0 95.1557093426%
}

.vaticancity {
    background-position: 0 95.5017301038%
}

.venezuela {
    background-position: 0 95.8477508651%
}

.vietnam {
    background-position: 0 96.1937716263%
}

.virginislandsbritish {
    background-position: 0 96.5397923875%
}

.virginislandsus {
    background-position: 0 96.8858131488%
}

.wales {
    background-position: 0 97.23183391%
}

.wallisandfutuna {
    background-position: 0 97.5778546713%
}

.westernsahara {
    background-position: 0 97.9238754325%
}

.westindies {
    background-position: 0 98.2698961938%
}

.world {
    background-position: 0 98.615916955%
}

.yemen {
    background-position: 0 98.9619377163%
}

.zambia {
    background-position: 0 99.3079584775%
}

.zanzibar {
    background-position: 0 99.6539792388%
}

.zimbabwe {
    background-position: 0 100%
}


.current-day-1:after {
    content: "1"
}

.current-day-2:after {
    content: "2"
}

.current-day-3:after {
    content: "3"
}

.current-day-4:after {
    content: "4"
}

.current-day-5:after {
    content: "5"
}

.current-day-6:after {
    content: "6"
}

.current-day-7:after {
    content: "7"
}

.current-day-8:after {
    content: "8"
}

.current-day-9:after {
    content: "9"
}

.current-day-10:after {
    content: "10"
}

.current-day-11:after {
    content: "11"
}

.current-day-12:after {
    content: "12"
}

.current-day-13:after {
    content: "13"
}

.current-day-14:after {
    content: "14"
}

.current-day-15:after {
    content: "15"
}

.current-day-16:after {
    content: "16"
}

.current-day-17:after {
    content: "17"
}

.current-day-18:after {
    content: "18"
}

.current-day-19:after {
    content: "19"
}

.current-day-20:after {
    content: "20"
}

.current-day-21:after {
    content: "21"
}

.current-day-22:after {
    content: "22"
}

.current-day-23:after {
    content: "23"
}

.current-day-24:after {
    content: "24"
}

.current-day-25:after {
    content: "25"
}

.current-day-26:after {
    content: "26"
}

.current-day-27:after {
    content: "27"
}

.current-day-28:after {
    content: "28"
}

.current-day-29:after {
    content: "29"
}

.current-day-30:after {
    content: "30"
}

.current-day-31:after {
    content: "31"
}
