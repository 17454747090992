@import "./accets/styles/global.scss";
@import "./accets/styles/globalIcons.scss";
@import "./accets/images/png/language/globalFlagPosition.scss";

@import "./accets/chunks/617.fdac4f4c.chunk.css";
@import "./accets/chunks/44.4ee1d991.chunk.css";
@import "./accets/chunks/main.7ebc1a76.chunk.css";
@import "./accets/chunks/79.a6c00a43.chunk.css";
@import "./accets/chunks/styles__ltr.css";
@import "./accets/chunks/fontsAll.css";
@import "./accets/chunks/styles.6c6db885dbc196b08baa.css";
@import "./accets/chunks/77.6c87e944.chunk.css";
@import "./accets/chunks/promotion.css";
@import "./accets/chunks/gamesStyles.css";
// @import "./accets/chunks/main.3b2000da.chunk.css";
html {
  overflow: scroll;
}

body {
  margin: 0;
  padding: 0;
  background-color: $backgroud-color;
  user-select: none;
  cursor: default;
}

body::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

body::-webkit-scrollbar-thumb {
  background: rgba(153, 153, 153);
  border-radius: 4px;
}

body::-webkit-scrollbar-track {
  background: rgba(50, 50, 50);
  border-radius: 4px;
}
@font-face {
  font-family: "BetConstruct-Icons";
  src: url(./accets/fonts/chunks/BetConstruct-Icons.e77508a6.ttf);
  src: url(./accets/fonts/chunks/BetConstruct-Icons.e77508a6.ttf)
      format("embedded-opentype"),
    url(./accets/fonts/chunks/BetConstruct-Icons.e77508a6.ttf)
      format("truetype"),
    url(./accets/fonts/chunks/BetConstruct-Icons.e77508a6.ttf) format("woff"),
    url(./accets/fonts/chunks/BetConstruct-Icons.e77508a6.ttf) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  src: local("Roboto Bold"),
  url(./accets/fonts/chunks/RobotoBold.9b572e19.woff2) format("woff2");
  font-display: fallback
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.24s 0.24s, border-color 0.24s;
  transition: 0.24s;
  color: $white-color-09;
}

.s_small {
  width: auto;
  height: 28px;
  padding: 0 15px;
}

.a_outline {
  background: none;
  border: 1px solid $white-color-06;
  transition: 0.24s;
}

.flag_bc {
  width: 22px;
  height: 14px;
  flex-shrink: 0;
  background: url("./accets/images/png/language/flags.4185ae2c.png") no-repeat 0
    0/100%;
}
