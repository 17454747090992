.castom-inner-bc {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  max-width: 944px;
  height: 80vh;
  min-height: 470px;
  padding-top: 44px;
  padding-bottom: 15px;
}

.custom-scroll-container {
  flex: auto;
  margin: 0;
  -webkit-margin-end: 6px;
  margin-inline-end: 6px;
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
  -webkit-padding-end: 5px;
  padding-inline-end: 5px;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.custom-list-container {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)) !important;
}
