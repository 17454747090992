.base-carousel-banner {
  .carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
  }

  .carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
  }

  /* hide scrollbar in webkit browser */
  .carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
  }

  .carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }



  .carousel-container {
    .carousel-count-arrow-container {
      padding: 0 15px;
      display: flex;
      position: absolute;
      color: rgba(var(--b-sc-rgb),.8);
      font-size: 14px;
      z-index: 1;
    }

    .carousel-count-arrow-container.with-count {
      top: 20px;
      color: hsla(0,0%,100%,.8);
    }

    .carousel-count-arrow-container.with-count i.carousel-arrow-left,
    .carousel-count-arrow-container.with-count i.carousel-arrow-right {
      position: relative;
      z-index: 9;
    }

    .carousel-count-arrow-container.with-count i.carousel-arrow-left {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }

    .carousel-count-arrow-container.with-count i {
      width: 18px;
      height: 18px;
      margin-top: 2px;
      border-radius: 50%!important;
      background: rgba(0,0,0,.9);
    }

    .carousel-count-arrow-container i {
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: auto;
    }

    .carousel-count-arrow-container.with-count i:first-child {
      -webkit-margin-end: -10px;
      margin-inline-end: -10px;
    }

    .carousel-count-arrow-container.with-count i:last-child {
      -webkit-margin-start: -10px;
      margin-inline-start: -10px;
    }

    .carousel-count-arrow-container.with-count i.carousel-arrow-right {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }

    .carousel-count-arrow-container.with-count i:before {
      font-size: 10px;
      opacity: 1;
    }

    .bc-i-small-arrow-left:before {
      content: "\e314";
    }

    .carousel-count-arrow-container.with-count .carousel-count-arrow {
      position: relative;
      overflow: hidden;
      background: none;
      width: 68px;
      height: 22px;
      padding: 0 13px;
      font-size: 12px;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
    }

    .carousel-count-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      margin: 0 1px;
      padding: 0 5px;
      background: hsla(0,0%,100%,.3);
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }

    .carousel-count-arrow-container.with-count .carousel-count-arrow:before {
      left: -18px;
    }

    .carousel-count-arrow-container.with-count .carousel-count-arrow:after {
      right: -18px;
    }

    .carousel-count-arrow-container.with-count .carousel-count-arrow:after, .carousel-count-arrow-container.with-count .carousel-count-arrow:before {
      content: "";
      position: absolute;
      top: 50%;
      width: 28px;
      height: 22px;
      border-radius: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: -1;
    }

    @supports not (stroke-color:transparent) {
      .carousel-count-arrow-container.with-count .carousel-count-arrow:before {
        box-shadow: -20px 0 0 44px rgba(0,0,0,.9)
      }
    }

    @supports not (stroke-color:transparent) {
      .carousel-count-arrow-container.with-count .carousel-count-arrow:after {
        box-shadow: 20px 0 0 44px rgba(0, 0, 0, .9)
      }
    }

    @supports(stroke-color:transparent) {
      .carousel-count-arrow-container.with-count .carousel-count-arrow:before {
        box-shadow: -20px 0 0 45px rgba(0,0,0,.9)
      }
    }

    @supports(stroke-color:transparent) {
      .carousel-count-arrow-container.with-count .carousel-count-arrow:after {
        box-shadow: 20px 0 0 45px rgba(0,0,0,.9)
      }
    }
  }
}

[dir=ltr] .carousel-count-arrow-container.with-count {
  right: 0
}

[dir=rtl] .carousel-count-arrow-container.with-count {
  left: 0
}
