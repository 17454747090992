.custom-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgba(var(--b-sc-rgb), 0.15);
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 14px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
