.sp-fav-head-bc {
  width: 100%;
  height: 24px;
}
.sp-fav-h-side-bc,
.sp-fav-head-bc {
  display: inline-block;
  vertical-align: top;
}
.sp-fav-h-side-bc {
  height: 100%;
}
[dir="ltr"] .sp-fav-h-side-bc.left {
  float: left;
}
[dir="ltr"] .sp-fav-h-side-bc.right,
[dir="rtl"] .sp-fav-h-side-bc.left {
  float: right;
}
[dir="rtl"] .sp-fav-h-side-bc.right {
  float: left;
}
.sp-fav-h-tabs-holder-bc {
  height: 100%;
  white-space: nowrap;
  border-radius: 4px 4px 0 0;
  background-color: #2b2b40;
  background-color: var(--skin-palette-5);
  box-shadow: inset 0 -2px 0 0 #353550;
  box-shadow: 0 -2px 0 0 var(--skin-palette-6) inset;
}
.sp-fav-h-tab-bc {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding: 0 8px;
  font-weight: 700;
  font-size: 14px;
  color: #b3b3b3;
  color: var(--text-palette-15);
  line-height: 24px;
  text-transform: capitalize;
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.24s;
  cursor: pointer;
}
.sp-fav-h-tab-bc.active,
.sp-fav-h-tab-bc:hover {
  background-color: #212131;
  background-color: var(--skin-palette-4);
  color: var(--b-sc);
}
.sp-fav-h-tab-bc.active {
  box-shadow: 0 -2px 0 0 var(--oc-1) inset;
}
.sp-fav-h-clear-btn-bc {
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  font-weight: 400;
  font-size: 12px;
  color: #bfbfbf;
  color: var(--text-palette-16);
  white-space: nowrap;
  line-height: 14px;
  transition: color 0.24s ease;
  cursor: pointer;
}
.sp-fav-h-clear-btn-bc:first-letter {
  text-transform: capitalize;
}
.sp-fav-h-clear-btn-bc:hover {
  color: var(--b-sc);
}
.sp-fav-item-holder-bc {
  margin-bottom: 8px;
}
.sp-fav-item-holder-bc:last-child {
  margin-bottom: 0;
}
.sp-sub-list-bc {
  transition: all 0.24s;
}
.sp-sub-list-bc.active {
  max-height: 15000px;
}
.sp-sub-list-bc.active > .sp-s-l-head-bc .sp-s-l-h-arrow-icon-bc:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sp-sub-list-bc.active > .sp-s-l-b-content-bc {
  opacity: 1;
  max-height: 14000px;
  pointer-events: auto;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sp-sub-list-bc .sp-s-l-h-title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: auto;
  height: 100%;
}
[dir="rtl"]
  .sp-sub-list-bc
  .sp-s-l-h-title-content
  .sp-s-l-h-title-bc
  + .sp-s-l-h-title-bc {
  margin-top: -4px;
}
.sp-s-l-head-bc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sp-s-l-h-flag-bc {
  flex-shrink: 0;
}
.sp-s-l-h-flag-bc,
.sp-s-l-h-title-bc {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.sp-s-l-h-title-bc {
  text-transform: capitalize;
}
.sp-s-l-b-c-i-g-league-c-bc {
  flex-shrink: 0;
}
.sp-s-l-h-arrow-icon-bc {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 11px;
}
.sp-s-l-b-content-bc {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.24s;
  overflow: hidden;
}
.sp-s-l-b-content-bc:empty {
  display: none;
}
.sp-s-l-b-c-i-title-bc {
  flex: auto;
  margin: 0 10px;
  color: rgba(var(--b-sc-rgb), 0.9);
  font-size: 13px;
}
.sp-s-l-b-c-i-title-bc + .sp-s-l-h-flag-bc {
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}
.sp-s-l-b-c-i-g-count-bc {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 13px;
  min-width: 15px;
  text-align: end;
}
.competition-ico-arr-bc {
  flex-shrink: 0;
  width: 11px;
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
  color: rgba(var(--b-sc-rgb), 0.6);
  font-size: 11px;
}
[dir="rtl"] .competition-ico-arr-bc:before {
  content: "оЊ”";
}
.pp-sport-list-holder-bc {
  position: relative;
  height: 100%;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.e-sports .left-menu-scroll > .sport-search-bc {
  position: -webkit-sticky;
  position: sticky;
  padding: 10px;
  height: 56px;
  top: 0;
  margin-bottom: 2px;
  background: var(--b);
  border-radius: 4px;
  z-index: 9;
}
.e-sports .left-menu-scroll > .sport-search-bc:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--b-sc-rgb), 0.05);
  pointer-events: none;
}
[dir="ltr"] .e-sports .left-menu-scroll > .sport-search-bc:after {
  left: 0;
}
[dir="rtl"] .e-sports .left-menu-scroll > .sport-search-bc:after {
  right: 0;
}
.e-sports .left-menu-scroll > .sport-search-bc .ss-icon-holder-bc {
  bottom: 0;
  margin: auto;
}
[dir="ltr"] .e-sports .left-menu-scroll > .sport-search-bc .ss-icon-holder-bc {
  right: 10px;
}
[dir="rtl"] .e-sports .left-menu-scroll > .sport-search-bc .ss-icon-holder-bc {
  left: 10px;
}
.e-sports .left-menu-scroll > .sport-search-bc .sport-search-result-bc {
  width: calc(100% - 20px);
  top: 48px;
}
[dir="ltr"]
  .e-sports
  .left-menu-scroll
  > .sport-search-bc
  .sport-search-result-bc {
  left: 10px;
}
[dir="rtl"]
  .e-sports
  .left-menu-scroll
  > .sport-search-bc
  .sport-search-result-bc {
  right: 10px;
}
.e-sports .left-menu-scroll > .sp-sub-list-bc.active > .sp-s-l-head-bc {
  top: 56px;
}
.e-sports .left-menu-scroll > .sp-sub-list-bc.active .sp-s-l-b-content-bc {
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.left-menu-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
}
.left-menu-scroll > .sp-sub-list-bc {
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-head-bc .sp-s-l-h-title-bc {
  color: var(--b-sc);
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-b-content-bc > div:not([class]) {
  display: flex;
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > div:not([class])
  .bs-favorite-remove-bc {
  align-items: center;
  border-top: 1px solid rgba(var(--b-sc-rgb), 0.1);
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-b-content-bc > .sp-sub-list-bc {
  padding: 0 5px;
  box-shadow: inset 0 1px 0 0 rgba(var(--b-sc-rgb), 0.1);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  > .sp-s-l-head-bc {
  padding: 0 5px;
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  > .sp-s-l-head-bc
  .sp-s-l-h-title-bc {
  color: rgba(var(--b-sc-rgb), 0.8);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc.active {
  padding-bottom: 5px;
  background: rgba(var(--b-sc-rgb), 0.1);
  box-shadow: inset 0 1px 0 0 transparent;
}
.left-menu-scroll > .sp-sub-list-bc + .sp-sub-list-bc,
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  + .sp-sub-list-bc {
  margin-top: 2px;
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-b-content-bc > .sp-sub-list-bc {
  background: rgba(var(--b-sc-rgb), 0.05);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-s-l-b-content-bc {
  padding: 5px;
  background: var(--b);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc
  > .sp-s-l-head-bc {
  background: rgba(var(--b-sc-rgb), 0.15);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc
  > .sp-s-l-head-bc
  .sp-s-l-h-title-bc {
  color: rgba(var(--b-sc-rgb), 0.8);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc.selected
  > .sp-s-l-head-bc,
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc:hover
  > .sp-s-l-head-bc {
  background: rgba(var(--b-sc-rgb), 0.2);
}
.left-menu-scroll > .sp-sub-list-bc.active > .sp-s-l-head-bc {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--b);
  box-shadow: inset 0 0 0 18px rgba(var(--b-sc-rgb), 0.1);
  z-index: 1;
}
.sp-sub-list-bc {
  max-height: 34px;
}
.sp-sub-list-bc .sp-sub-list-bc .sp-s-l-b-content-bc {
  border-radius: 4px;
}
.sp-sub-list-bc .form-control-bc {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px 5px 0;
  border-top: 1px solid rgba(var(--b-sc-rgb), 0.1);
}
.sp-sub-list-bc .form-control-select-bc {
  width: auto;
}
.sp-sub-list-bc .multi-select-label-bc {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.sp-s-l-head-bc {
  height: 34px;
  padding: 0 10px;
  cursor: pointer;
  transition: background 0.24s;
  border-radius: 4px;
}
.sp-s-l-h-flag-bc {
  width: 20px;
  text-align: center;
  font-size: 18px;
}
.sp-s-l-h-flag-bc.flag-bc {
  width: 18px;
  height: 12px;
}
.sp-s-l-h-title-bc {
  font-size: 12px;
}
.sp-s-l-b-c-i-g-league-c-bc {
  color: rgba(var(--b-sc-rgb), 0.5);
  font-size: 12px;
}
.sp-s-l-h-arrow-icon-bc {
  width: 24px;
  height: 24px;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  text-align: center;
  border-radius: 4px;
  line-height: 23px;
  transition: color 0.24s, background 0.24s;
}
.sp-s-l-h-arrow-icon-bc:before {
  display: block;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
[dir="rtl"] .sp-s-l-h-arrow-icon-bc.bc-i-small-arrow-right {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sp-s-l-b-c-item-bc {
  display: flex;
  align-items: center;
  height: 35px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.24s;
}
.sp-s-l-b-c-item-bc + .sp-s-l-b-c-item-bc {
  margin-top: 2px;
}
.sp-s-l-b-c-item-bc:last-child {
  margin-bottom: 3px;
}
.sp-s-l-b-c-item-bc.active,
.sp-s-l-b-c-item-bc:hover {
  background: rgba(var(--b-sc-rgb), 0.2);
}
.market-game-section {
  padding: 6px 10px 10px;
  background: rgba(var(--b-sc-rgb), 0.15);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: background 0.1s, box-shadow 0.1s;
}
.market-game-section:not(.active) {
  cursor: pointer;
}
.market-game-section.active,
.market-game-section:hover {
  background: rgba(var(--b-sc-rgb), 0.2);
}
.market-game-section:before {
  content: "";
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
}
[dir="ltr"] .market-game-section:before {
  left: 0;
}
[dir="rtl"] .market-game-section:before {
  right: 0;
}
.market-game-section + .market-game-section {
  margin-top: 2px;
}
.market-game-team {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.market-game-team-name {
  flex: auto;
  color: rgba(var(--b-sc-rgb), 0.9);
}
.market-game-odd {
  flex-shrink: 0;
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  color: var(--accent);
}
.market-game-part-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 22px;
  color: rgba(var(--b-sc-rgb), 0.4);
}
.market-game-part-container > i {
  font-size: 10px;
}
.market-game-part-container > b {
  height: 15px;
  min-width: 23px;
  padding: 0 2px;
  color: rgba(var(--b-sc-rgb), 0.4);
  background: rgba(var(--b-rgb), 0.8);
  border-radius: 4px;
  text-align: center;
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
}
.market-game-part-container > b,
.market-game-part-container > i {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
}
.market-game-part-container .bc-loader.small .bc-loader-contain {
  max-width: 10px;
  height: 10px;
  line-height: 10px;
}
.market-game-part-container .bc-loader.small .vivaro-loader {
  width: 10px;
}
.market-game-part {
  flex: auto;
  font-size: 11px;
  line-height: 22px;
}
.market-game-additional-info-container {
  display: flex;
  align-items: center;
  color: rgba(var(--b-sc-rgb), 0.4);
  font-size: 11px;
}
.market-game-additional-info {
  flex: auto;
}
.market-game-additional-info-time {
  flex-shrink: 0;
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  font-weight: 400;
}
.popular-competitions-bc {
  overflow: hidden;
}
.sp-s-l-b-c-i-fav-icon-bc {
  flex-shrink: 0;
  height: 25px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 16px;
  line-height: 25px;
}
[dir="ltr"] .sp-s-l-b-c-i-fav-icon-bc {
  border-right: 1px solid var(--b);
}
[dir="rtl"] .sp-s-l-b-c-i-fav-icon-bc {
  border-left: 1px solid var(--b);
}
.sp-s-l-b-c-i-sport-icon-bc {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 16px;
}
.sp-s-l-b-c-i-g-live-count-bc {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 6px;
  text-align: center;
  border-radius: 6px;
  color: #fff;
  background: var(--oc-1);
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  font-size: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.sp-s-l-b-c-i-g-live-count-bc > i {
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
  font-size: 14px;
}
.today-bets i {
  position: relative;
}
.today-bets i:after {
  font-size: 10px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-family: Roboto;
}
.current-day-1:after {
  content: "1";
}
.current-day-2:after {
  content: "2";
}
.current-day-3:after {
  content: "3";
}
.current-day-4:after {
  content: "4";
}
.current-day-5:after {
  content: "5";
}
.current-day-6:after {
  content: "6";
}
.current-day-7:after {
  content: "7";
}
.current-day-8:after {
  content: "8";
}
.current-day-9:after {
  content: "9";
}
.current-day-10:after {
  content: "10";
}
.current-day-11:after {
  content: "11";
}
.current-day-12:after {
  content: "12";
}
.current-day-13:after {
  content: "13";
}
.current-day-14:after {
  content: "14";
}
.current-day-15:after {
  content: "15";
}
.current-day-16:after {
  content: "16";
}
.current-day-17:after {
  content: "17";
}
.current-day-18:after {
  content: "18";
}
.current-day-19:after {
  content: "19";
}
.current-day-20:after {
  content: "20";
}
.current-day-21:after {
  content: "21";
}
.current-day-22:after {
  content: "22";
}
.current-day-23:after {
  content: "23";
}
.current-day-24:after {
  content: "24";
}
.current-day-25:after {
  content: "25";
}
.current-day-26:after {
  content: "26";
}
.current-day-27:after {
  content: "27";
}
.current-day-28:after {
  content: "28";
}
.current-day-29:after {
  content: "29";
}
.current-day-30:after {
  content: "30";
}
.current-day-31:after {
  content: "31";
}
.today-bets i:after {
  line-height: 21px;
}
